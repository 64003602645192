// imports react and next
import React, { useEffect, useMemo, useState } from "react";
import { Image as DatoImage } from "react-datocms";
import dynamic from "next/dynamic";

// imports components
const FormWithDatePicker = dynamic(() => import("@/common/UI/Form"));
const AppLoader = dynamic(() => import("@/common/AppLoader"));
const isServer = typeof window === "undefined";
const OwlCarousel = !isServer ? dynamic(import("react-owl-carousel")) : null;

// imports utilities
import { isEmpty } from "@/utils/functions";

// imports assets
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function MainSlider(props) {
  const {
    sliderData,
    siteConfig,
    smallSlider = false,
    isLoading = true,
    smallSliderHeight = "h-[374px]",
  } = props;

  const [showData, setShowData] = useState(false);

  const options = useMemo(() => {
    return {
      loop: true,
      margin: 0,
      nav: true,
      items: 1,
      lazyLoad: true,
      dots: false,
      smartSpeed: 800,
      lazyLoadEager: 3,
      responsive: {},
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (sliderData?.length > 0) setShowData(true);
    }, 500);
  }, [sliderData]);

  return (
    <>
      {showData ? (
        <>
          <OwlCarousel
            className={`relative owl-carousel owl-theme owl-tamaro owl-arrow-outside overflow-hidden ${
              smallSlider
                ? `${smallSliderHeight} small-slider `
                : "sm:h-[704px] xxs:h-[576px] owl-navigation"
            }`}
            {...options}
          >
            <>
              {sliderData?.map((item, index) => {
                return (
                  <div key={index} className="relative">
                    <div
                      className={`relative ${
                        smallSlider ? `${smallSliderHeight}` : "sm:h-[704px] xxs:h-[576px]"
                      }`}
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {item?.responsiveImage && (
                        <DatoImage
                          data={{
                            src: item?.responsiveImage?.src,
                            alt: item?.alt || `slider-image-${index + 1}`,
                            title: item?.title || "title",
                            layout: "fill",
                            height: item.responsiveImage.height,
                            width: item.responsiveImage.width,
                            placeholder: !isEmpty(item?.blurUpThumb) ? "blur" : "empty",
                            blurDataURL: item?.blurUpThumb,
                          }}
                          lazyLoad={false}
                          className="min-w-full min-h-full"
                          pictureClassName="object-cover"
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          </OwlCarousel>
          {!smallSlider && <FormWithDatePicker siteConfig={siteConfig} />}
        </>
      ) : (
        isLoading && <AppLoader />
      )}
    </>
  );
}

export default MainSlider;
